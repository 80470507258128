<template>
	<section class="home-banner">
		<div class="banner-bg">
		</div>
		<div class="banner-container">
			<div class="banner-content">
				<div>
					<div class="main-logo">
					</div>
					<div>
						<div class="text">助力金融机构</div>
						<div class="text">为跨境企业提供金融服务</div>
						<div class="sub-text">【因为专注，所以专业】</div>
					</div>
				</div>
			</div>
		</div>
		<div class="detail-icon-container">
			<div class="detail-icon"></div>
		</div>
	</section>
</template>

<script>
	export default {}
</script>

<style scoped>
	.home-banner {
		position: relative;
	}

	.main-logo {
		width: 100px;
		height: 100px;
	}

	.banner-bg {
		background-image: url('/img/banner.jpg');
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		height: 819px;
	}

	.banner-container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.banner-content {
		background-color: rgba(255, 255, 255, 0.7);
		width: 100%;
		padding: 30px 0;
	}
	.banner-content > div {
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		max-width: 600px;
	}

	.bannner-content div.container-context {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
	}

	.banner-content .text {
		font-weight: 700;
		font-style: normal;
		color: #f7ffb5;
		font-kerning: none;
		text-decoration: none;
		-webkit-text-stroke: 1px rgba(0, 0, 0, 0.4);
		font-size: 36px;
	}

	.banner-content .sub-text {
		font-weight: 700;
		font-style: normal;
		color: rgb(0, 71, 103);
		font-kerning: none;
		text-decoration: none;
		font-size: 18px;
		text-align: right;
	}

	.detail-icon-container {
		position: absolute;
		height: 40px;
		padding: 10px 0;
		right: 25px;
		bottom: 25px;
	}

	.detail-icon {
		background-image: url('/img/right-arrow.png');
		background-repeat: no-repeat;
		background-size: contain;
		width: 65px;
		height: 20px;
	}
</style>