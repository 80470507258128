<template>
	<div class="container">
		<div>
			<img src="/img/menu.svg" alt="menu" class="icon" />
			<div class="title">我们的优势</div>
		</div>
		<data-detail></data-detail>
		<risk-detail></risk-detail>
		<professional-detail></professional-detail>
	</div>
</template>

<script>
	import DataDetail from './data-detail'
	import RiskDetail from './risk-detail'
	import ProfessionalDetail from './professional-detail'
	export default {
		components: {
			DataDetail,
			RiskDetail,
			ProfessionalDetail
		}
	}
</script>

<style scoped>
	* {
		--bg-color: #F7F9E7;
		--indentation-left: 40px;
		--space-left: 15px;
		--linear-gradient-left: #8F41E9;
		--linear-gradient-right: #F8FDBD;
		--button-bg-color: #e6ff16;
	}
	.container {
		background-color: var(--bg-color);
		padding-top: 30px;
		padding-bottom: 30px;
	}
	
	.container .icon {
		width: var(--indentation-left);
		height: var(--indentation-left);
	}
	.container .title {
		font-weight: 700;
		font-style: normal; 
		color: rgb(0, 71, 103); 
		font-kerning: none; 
		text-decoration: none;
		font-size: 24px;
		margin-left: var(--space-left);
	}
</style>