<template>
	<div>
		<home-banner></home-banner>
		<home-details></home-details>
		<home-about-us></home-about-us>
	</div>
</template>

<script>
	import Components from '@/components/index'
	export default {
		components: {
			...Components
		}
	}
</script>

<style>
</style>