<template>
	<section id="home-details">
		<detail-section1></detail-section1>
		<detail-section2></detail-section2>
		<div class="gwo-water-print"></div>
	</section>
</template>

<script>
	import DetailComponents from './details/index.js'
	export default {
		components: {
			...DetailComponents
		}
	}
</script>

<style scoped>
	#home-details {
		position: relative;
		overflow: hidden;
	}

	.gwo-water-print {
		background-image: url('/img/gwo-water-print.png');
		background-repeat: no-repeat;
		background-size: cover;
		position: absolute;
		top: 0;
		right: 0;
		width: 500px;
		height: 149px;
		transform: translate(15%, 10%);
	}
</style>